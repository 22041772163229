import React from 'react';
import '../../../src/App.css'

const NotFound = () => {
  return (
    <>
    <div className="not-found-container" >
      <h1 >404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
    </>
  );
};

export default NotFound;