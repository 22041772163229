import { useEffect } from "react";
import { useAuth } from "../provider/AuthProvider";

const withAuth = (WrappedComponent) => {
   
    const WithAuth = (props) => {
        const {user} = useAuth()
      
  
      useEffect(() => {
        // Check if the user is authenticated
        if (!user) {
          // Redirect to the login page or any other protected route
          window.location.href = "/Signup"
        }
      }, [user]);
  
      // If the user is authenticated, render the wrapped component
      // Otherwise, you can show a loading spinner or a message indicating that the user is not authenticated
      return user ? <WrappedComponent {...props} /> : <div>Loading...</div>;
    };
  
    return WithAuth;
  };


  export default withAuth
  

  
  
  
  