import React from "react";
import Address from "../../assets/Address.png";
import mail from "../../assets/mail.png";
import call from "../../assets/call.png";
import facebook from "../../assets/facebook.png";
import twitter from "../../assets/twitter.png";
import google from "../../assets/google.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { redirect } from 'react-router-dom';
// import { useDispatch, useSelector } from "react-redux";
// import { contactUserAsync } from "../../Redux/Reducer/ContactReducer";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "../../Services/UserServices";
import { useState } from "react";

const schema = yup.object().shape({
  firstName: yup
    ?.string()
    .trim()
    ?.required("first name required*")
    .min(3, "first name must be longer than or equal to 3 characters")
    .max(50, "first name must be shorter than or equal to 50 characters")
    ?.matches(
      /^[a-zA-Z\s]*$/,
      "first name must not contain symbols or numbers"
    ),
  lastName: yup
    ?.string()
    .trim()
    .required("last name required*")
    .min(3, "last name must be longer than or equal to 3 characters")
    .max(50, "last name must be shorter than or equal to 50 characters")
    ?.matches(/^[a-zA-Z\s]*$/, "last name must not contain symbols or numbers"),
  email: yup
    .string()
    // .email(" ")
    .required("email required*")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "email must be a valid"
    ),
  subject: yup
    .string()
    .trim()
    .required("subject required*")
    .min(3, "subject must be longer than or equal to 3 characters")
    .max(50, "subject must be shorter than or equal to 50 characters"),
  message: yup
    ?.string()
    ?.required("message required*")
    .trim()
    .min(3, "message must be longer than or equal to 3 characters")
    .max(500, "message must be shorter than or equal to 500 characters"),
});

const Contact = () => {
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data) => {
    setLoading(true);

    let conData = {
      id: 0,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      subject: data.subject,
      message: data.message,
    };

    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
      const response = await axios.post(
        `${API_URL}/contact/create-or-update`,
        conData
      );

      if (response.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "contact",
          text: "Contact saved Successfully.",
        });
        reset();
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        // Show SweetAlert for email and mobile already existing
        Swal.fire({
          icon: "error",
          title: "Contact Failed",
          text: error.response.data.message,
        });
      } else {
        // Handle other error responses
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error?.message || "Network Error.",
        });
      }
    }
    setLoading(false);
  };
  return (
    <>
      <section className="container-fluid PageTitle_banner_section p-0">
        <div className="container">
          <h1 className="PageTitle_banner_heading">CONTACT US</h1>
        </div>
      </section>
      <section className="container-fluid contact_info_section py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-4 m-auto">
              <div className="why_choose_us_item">
                <div className="why_choose_us_item_img ">
                  <img src={Address} alt="" />
                </div>
                <div className="why_choose_us_item_content text-start">
                  <h5 className="fw-bold text-muted">Address</h5>
                  <p>Sydney-Australia</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-4 m-auto">
              <div className="why_choose_us_item">
                <div className="why_choose_us_item_img">
                  <img src={mail} alt="" />
                </div>
                <div className="why_choose_us_item_content text-start">
                  <h5 className="fw-bold text-muted">E-mail</h5>
                  <p>admin@ductsizerpro.com</p>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-4 m-auto">
              <div className="why_choose_us_item">
                <div className="why_choose_us_item_img">
                  <img src={call} alt="" />
                </div>
                <div className="why_choose_us_item_content text-start">
                  <h5 className="fw-bold text-muted">Phone Number </h5>
                  <p>1 (609) 123-45-67</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="container-fluid Touch_section py-5 ">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-4">
              <h3 className="fw-bold">Get In Touch</h3>
              <p>
                We believe our warranties set us apart from our competitors and
                show our commitment to quality work and service. We believe our
                warranties set us apart from our competitors and show our
                commitment to quality work and service.
              </p>
              <h3 className="fw-bold my-4">Follow us</h3>
              <a className="text-decoration-none" href="">
                <img src={facebook} className="social-media" alt="" />
              </a>
              <a className="text-decoration-none" href="">
                <img src={twitter} className="social-media" alt="" />
              </a>
              <a className="text-decoration-none" href="">
                <img src={google} className="social-media" alt="" />
              </a>
            </div> */}
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <input
                      {...register("firstName")}
                      type="text"
                      className="form-control  form-control-lg"
                      placeholder="First name"
                    />
                    <p style={{ color: "red" }}>{errors.firstName?.message}</p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      {...register("lastName")}
                      type="text"
                      className="form-control  form-control-lg"
                      placeholder="Last name"
                    />
                    <p style={{ color: "red" }}>{errors.lastName?.message}</p>
                  </div>
                  <div className="col-md-12 mb-3">
                    <input
                      {...register("email")}
                      type="email"
                      className="form-control  form-control-lg"
                      placeholder="Email"
                    />
                    <p style={{ color: "red" }}>{errors.email?.message}</p>
                  </div>
                  <div className="col-md-12 mb-3">
                    <input
                      {...register("subject")}
                      type="text"
                      className="form-control  form-control-lg"
                      placeholder="Subject"
                    />
                    <p style={{ color: "red" }}>{errors.subject?.message}</p>
                  </div>
                  <div className="col-md-12 mb-3">
                    <textarea
                      {...register("message")}
                      className="form-control form-control-lg"
                      placeholder="Message"
                      cols="30"
                      rows="5"
                    ></textarea>
                    <p style={{ color: "red" }}>{errors.message?.message}</p>
                  </div>
                  <div>
                    <button className="btn btn_submit">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "  Submit"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="container-fluid">
        <div className="row">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7157.406093584441!2d73.01710454171354!3d26.238836658220727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418b54195f2839%3A0xb3633326d8efe16f!2sMANISHA%20MEHANDI%20ART!5e0!3m2!1sen!2sin!4v1688728656125!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title=" "
          />
        </div>
      </section> */}
    </>
  );
};

export default Contact;
