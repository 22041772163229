import React from "react";
import whyChooseImg1 from "../../assets/UserFriendlyInterface_icon.png";
import whyChooseImg2 from "../../assets/QuickAccurrate_icon.png";
import whyChooseImg3 from "../../assets/ProjectSavedCloud_icon.png";
import step_arrow from "../../assets/step_arrow.png";
import hero_banner from "../../assets/hero_banner.png";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import DSPRO from "../../assets/Video/DSPRO.mp4";
const Home = () => {
  return (
    <div>
      <Helmet>
        <title>
          Duct sizer Pro an innovative online calculator for efficiently sizing
          HVAC Ducts
        </title>
        <meta
          name="description"
          content="Optimize HVAC efficiency with our innovative online duct sizing tool. Accurately calculate duct dimensions for better airflow. Streamline your HVAC design process."
        />
        <link rel="canonical" href="https://www.ductsizerpro.com/" />
      </Helmet>
      <section className="container-fluid hero_banner_section p-0">
        <div className="hero_banner_img">
          <img
            src={hero_banner}
            alt="Online Calculator for Sizing Hvac Ducts"
          />
        </div>
      </section>
      <section className="container-fluid why_choose_us_section my-5">
        <div className="container">
          <div className="row">
            <div className="con-xl-4 col-lg-4 col-md-6 col-12 mb-4 m-auto">
              <div className="why_choose_us_item text-center">
                <div className="why_choose_us_item_img">
                  <img
                    src={whyChooseImg1}
                    alt="User Friendly Duct Sizing Tool"
                  />
                </div>
                <div className="why_choose_us_item_content mt-3">
                  <h5 className="fw-bold">User Friendly Interface</h5>
                  {/* <p>Whether you are looking for quality service our company has you covered.</p> */}
                </div>
              </div>
            </div>
            <div className="con-xl-4 col-lg-4 col-md-6 col-12 mb-4 m-auto">
              <div className="why_choose_us_item text-center">
                <div className="why_choose_us_item_img">
                  <img
                    src={whyChooseImg2}
                    alt="Quick And Accurrate Duct Sizer"
                  />
                </div>
                <div className="why_choose_us_item_content mt-3">
                  <h5 className="fw-bold">Quick And Accurrate</h5>
                  {/* <p>Having problems with your heating or cooling system. Call us 24/7.</p> */}
                </div>
              </div>
            </div>
            <div className="con-xl-4 col-lg-4 col-md-6 col-12 mb-4 m-auto">
              <div className="why_choose_us_item text-center">
                <div className="why_choose_us_item_img">
                  <img
                    src={whyChooseImg3}
                    alt="Cloud based Hvac Air Duct Calculator"
                  />
                </div>
                <div className="why_choose_us_item_content mt-3">
                  <h5 className="fw-bold">Project Saved on the Cloud</h5>
                  {/* <p>We offer financing through reliable.Financial companies.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid Software_video_section">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-5 col-lg-6 col-md-7">
              <div className="Software_video_content">
                <h2 className="fw-bolder mb-3">Tutorial Video</h2>
                <p>
                  Watch the easy steps of creating a project and running a
                  calculation
                </p>
                <button
                  className="btn btn_watching_video mt-5"
                  data-bs-toggle="modal"
                  data-bs-target="#watch_video_modal"
                >
                  <i className="fa-regular fa-circle-play me-2"></i> watch the
                  video
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid Use_Software_section my-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="Use_Software_heading text-center">
                <h1> Online Duct Sizer</h1>
                <h2>How to Use Our Software</h2>
              </div>
            </div>
          </div>
          <div className="Use_Software_steps_area ">
            <div className="Use_Software_step_item mb-2">
              <div className="Use_Software_step_number m-auto">
                <div className="d-flex align-items-end">
                  <small>Step</small>
                  <span>1</span>
                </div>
              </div>
              <div className="Use_Software_step_content m-auto">
                <p>Sign in and create your project</p>
              </div>
            </div>
            <div className="Use_Software_step_arrow mx-3">
              <img src={step_arrow} alt="" />
            </div>
            <div className="Use_Software_step_item mb-2">
              <div className="Use_Software_step_number m-auto">
                <div className="d-flex align-items-end">
                  <small>Step</small>
                  <span>2</span>
                </div>
              </div>
              <div className="Use_Software_step_content m-auto">
                <p>
                  Add ducts , provide air quantity and friction loss or velocity
                </p>
              </div>
            </div>
            <div className="Use_Software_step_arrow mx-3">
              <img src={step_arrow} alt="" />
            </div>
            <div className="Use_Software_step_item mb-2">
              <div className="Use_Software_step_number m-auto">
                <div className="d-flex align-items-end">
                  <small>Step</small>
                  <span>3</span>
                </div>
              </div>
              <div className="Use_Software_step_content m-auto">
                <p>Calculate</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 d-flex jusfity-content-center">
              <p>
                We are pleased to introduce you to Duct Sizer Pro - your
                step-by-step solution for hassle-free duct calculation and
                sizing. Our user-friendly Ductulator tool simplifies the process
                of designing and sizing HVAC duct systems, ensuring optimal
                performance and energy efficiency. With Duct Sizer Pro, you can
                effortlessly navigate through various duct configurations,
                taking the guesswork out of the equation. Whether you're a
                seasoned HVAC professional or a DIY enthusiast, our intuitive
                interface makes duct sizing a breeze. Forget complex
                calculations – our tool does the heavy lifting for you. At Duct
                Sizer Pro, we prioritize user experience, providing a seamless
                platform for duct sizing without compromising accuracy. Our
                Ductulator incorporates industry-standard formulas and
                parameters, ensuring precision in every calculation. Maximize
                the efficiency of your HVAC system with Duct Sizer Pro. Our tool
                is designed to handle various duct shapes, sizes, and materials,
                allowing you to tailor your system to meet specific
                requirements. It's the go-to resource for anyone seeking a
                reliable and easy-to-use duct sizing solution. Experience the
                difference with Duct Sizer Pro – your trusted partner in HVAC
                duct design. Whether you're working on residential or commercial
                projects, our tool empowers you to make informed decisions for
                optimal comfort and energy efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="container-fluid Testimonials_section py-5">
    <div className="container">
        <div className="row mb-4">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                <div className="Testimonials_section_heading text-center">
                    <h1>Customer Testimonials</h1>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-8 col-lg-9 col-md-12 m-auto">
                <div className="Testimonials_contect_area text-center py-4">
                    <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled
                        and demoralized by the charms of pleasure of the moment, so blinded by desire, that they
                        cannot foresee the pain and trouble that are bound to ensue.</p>
                    <h4 className="mt-4 fw-bold">Wilson Trayer,</h4>
                </div>
            </div>
        </div>
    </div>
</section> */}
      <div
        className="modal fade"
        id="watch_video_modal"
        tabindex="-1"
        aria-labelledby="watch_video_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <iframe
                width="560"
                height="315"
                src={DSPRO}
                title="Video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
