import React from 'react'



export const flattenArray = (arr) => {
    return arr.reduce((result, item) => {
      result.push(item);
      if (item.children && item.children.length > 0) {
        result.push(...flattenArray(item.children));
      }
      return result;
    }, []);
  };




function DuctsList({ducts:ductsArray , unitType}) {

  const ducts = flattenArray(ductsArray)

  return (
    <div className="table-responsive" >
                  <table className="table" id="my-table-007">
                    <thead>
                      <tr>
                        <th>Duct Reference</th>
                        <th>Round Duct Diameter  {unitType === "SI" ? "(MM)" : "(IN)"}</th>
                        <th>Duct Width {unitType === "SI" ? "(MM)" : "(IN)"}</th>
                        <th>Duct Height {unitType === "SI" ? "(MM)" : "(IN)"}</th>
                        <th>Flow area {unitType === "SI" ? "(M2)" : "(IN2)"}</th>
                        <th>Air Quantity  {unitType === "SI" ? "(L/S)" : "(CFM)"}</th>
                        <th>Friction loss  {unitType === "SI" ? "(PA/M)" : "(IN/100FT)"}</th>
                        <th>Velocity {unitType === "SI" ? "(M/S)" : "(FPM)"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ducts.map((duct, index) => (
                        <tr key={index}>
                          <td>{duct?.name}</td>
                          <td>{duct?.diaMeter}</td>
                          <td>{duct?.width}</td>
                          <td>{duct?.height}</td>
                          <td>{duct?.flowArea}</td>
                          <td>{duct?.airQuantity}</td>
                          <td>{duct?.frictionLoss}</td>
                          <td>{duct?.velocity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
    </div>
  )
}

export default DuctsList