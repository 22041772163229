import './App.css'
import Header from './components/common/Header';
import Home from './components/Pages/Home'
import { BrowserRouter as Router, Routes, Route, json } from 'react-router-dom';
import About from './components/Pages/About';
import Footer from './components/common/Footer';
import Contact from './components/Pages/Contact'
import Pricing from './components/Pages/Pricing';
import Calculator from './components/Pages/Calculator';
import Signup from './components/Pages/signup';
import ResetPassword from './components/Pages/ResetPassword';
import { AuthProvider } from './provider/AuthProvider';
import ForgetPassword from './components/Pages/ForgetPassword';
import OtpConfirm from './components/OtpPage';
import NotFound from './components/Pages/NotFound';
import PaymentSuccessMessage from './components/Pages/PaymentSuccessMessage';
import PaymentFailedMessage from './components/Pages/PaymentFailed';
import Product from './components/Pages/Product';
import Terms_condition from './components/Pages/Terms_condition';
import FreeCalculator from './components/Pages/FreeCalculator';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { current } from '@reduxjs/toolkit';
import ScrollToTop from './components/common/ScrollToTop';
import BuyNow from './components/Pages/Buynow';


function App() {

  //local
  // ReactGA.initialize([
  //   {
  //     trackingId: "G-K0Z0EKCNEN",
  //   },
  //   {
  //     trackingId: "G-K0Z0EKCNEN",
  //   },
  // ]);

  //client live
  ReactGA.initialize([
    {
      trackingId: "G-65B2EWCMZW",
    },
    {
      trackingId: "G-65B2EWCMZW",
    },

  ]);

  const newGuid = uuidv4();;

  var startTime = Date.now();
  sessionStorage.setItem('startTime', startTime);

  function updateSessionStorageTime() {

    var currentTime = Date.now();
    var timeDifference = currentTime - startTime;
    var seconds = timeDifference / 1000;
    var minute = Math.floor(seconds / 60);
    var remaingsecond = Math.floor(seconds % 60);


    const storedata = localStorage.getItem('user');
    const userdata = JSON.parse(storedata);


    if (userdata == null) {
      const TrackGoogleAnalyticsEvent = (
        category,
        event_name,
        label,
        data
      ) => {

        let event_params = {
          category,
          label,
          ...data
        };
        // Send GA4 Event
        ReactGA.event(event_name, event_params);
      };
      TrackGoogleAnalyticsEvent(
        "User_Time_Tracking",
        `GuestUser ${newGuid} `,
        window.location.pathname + window.location.search,
        { SpentTime: `${minute}Min,${remaingsecond}Sec` }
      );

    }


  }
  setInterval(updateSessionStorageTime, 10000);



  // Send pageview with a custom path
  // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

  // function handleTabCloseEvent(event) {
  //   // event.preventDefault();
  //   // const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
  //   // event.returnValue = confirmationMessage;
  //   ReactGA.event({
  //     category: " ",
  //     action: "",
  //     label: "",
  //     value: "",

  //   });

  // }

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleTabCloseEvent);


  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabCloseEvent);
  //   };
  // }, []);


  return (
    <div>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/PaymentSuccess' element={<PaymentSuccessMessage />} />
            <Route path='/PaymentFailed' element={<PaymentFailedMessage />} />
            <Route path='/About' element={<About />} />
            <Route path='/Calculator' element={<Calculator />} />
            <Route path='/FreeCalculator' element={<FreeCalculator />} />
            <Route path='/Project' element={<Product />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/Pricing' element={<Pricing />} />
            <Route path='/Signup' element={<Signup />} />
            <Route path='/ForgotPassword' element={<ForgetPassword />} />
            <Route path='/ResetPassword' element={<ResetPassword />} />
            <Route path='/OtpVerification' element={<OtpConfirm />} />
            <Route path='/terms&Condition' element={<Terms_condition />} />
            <Route path='/Buynow' element={<BuyNow />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
