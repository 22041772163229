import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
import Swal from "sweetalert2";
import { API_URL } from "../../Services/UserServices";
import api from "../../Services/ApiService";

const Pricing = () => {
  const location = useLocation();
  const [SubscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [monthly, setMonthly] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [setUser, setFreeUser] = useState([]);
  const [validDays, setValidDays] = useState("");

  const FetchSubscriptionPlan = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/subscription/getAll`);
      if (response?.data?.statusCode === 200) {
        setSubscriptionPlans(response?.data?.data);
        setLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Pricing Not found.",
        });
        setLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while fetching data. Please try again later.",
      });
      setLoading(false);
    }
  };

  const getUserOne = async () => {
    try {
      // setLoading(true);
      let user = JSON.parse(localStorage.getItem("user") ?? "{}");
      const { data: res } = await api.get(
        API_URL + "/user/getOne-user/" + user.id
      );

      if (res?.statusCode === 200) {
        const currentObj = {
          id: res?.data?.id,
          name: user?.name,
          email: user?.email,
          role: "admin",
          paid: res?.data?.paid,
          userSubs: res?.data?.userSubs,
          isFirstTimeLogin: res?.data?.isFirstTimeLogin,
          subscription: res?.data?.subscription,
          token: user?.token,
        };
        localStorage.setItem("user", JSON.stringify(currentObj));
        // setLoading(false);
      }
    } catch (e) {
      // setLoading(false);
      Swal.fire({
        text: e?.message,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getUserOne();
      getSubList();
    }
  }, [user]);

  // useEffect(() => {
  //   if (!user) {
  //     navigate('/Signup'); // Redirect to the login page if user is not logged in
  //   } else {
  //     FetchSubscriptionPlan();
  //     getUserOne();
  //   }
  // }, [user]);

  useEffect(() => {
    // Function to fetch subscription plans
    const fetchData = async () => {
      FetchSubscriptionPlan();
      // Reset the toggle button state when the URL changes
      setMonthly(false);
    };

    fetchData();

    return () => {
      window.location.reload();
    };
  }, [location.pathname]);

  const handleSelectPlan = async (index) => {
    setSelectedPlan(SubscriptionPlans[index]);

    // Prepare the payment data to be sent in the POST request
  };

  const processPayment = async () => {
    if (!user) {
      // Swal.fire("Login required.").then(() => {
      // window.location.href = "Signup"; // Replace with the correct path to Login.js
      navigate("/Signup?redirect=/Pricing");
      // });
      return;
    }
    setLoading(true);

    const paymentData = {
      // amount: selectedPlan?.amount,
      amount: monthly ? selectedPlan?.monthly : selectedPlan?.yearly,
      description: selectedPlan?.description,
      email: selectedPlan?.email, // Replace with the actual email of the user
      subscriptionId: selectedPlan?.id,
      isYearly: !monthly,
    };

    try {
      // Make the POST request to the payment API
      const { data } = await axios.post(
        `${API_URL}/payment/create-order`,
        paymentData,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      // Check if the response status code is 200 (OK)
      if (data?.statusCode == 200 || data?.statusCode == 201) {
        // Handle the successful payment scenario here, e.g., show a success message, redirect to a success page, etc.

        window.location.href = data?.data?.approvalUrl;
        setLoading(false);
      } else {
        // Handle any other status codes (e.g., 4xx or 5xx) for error scenarios
        Swal.fire({
          icon: "error",
          title: "Payment Error",
          text: data?.message,
        });
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("user");
        window.location.href = "/Signup";
      } else {
        Swal.fire(error?.message);
      }
      setLoading(false);
    }
  };

  const toogleBtn = () => {
    setMonthly(!monthly);
  };

  const freeUserSubscription = async () => {
    if (!user) {
      navigate("/Signup?redirect=/Pricing"); // Redirect to the sign-in page with a redirect query parameter
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.get(`${API_URL}/payment/success-free-user`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (data?.statusCode == 200 || data?.statusCode == 201) {
        const user = JSON.parse(localStorage.getItem("user") ?? "{}");
        if (user) {
          user.paid = "paid";
          localStorage.setItem("user", JSON.stringify(user));
        }
        Swal.fire({
          title: "You are Subcribed for 1-month",
        });

        window.location.reload();
      } else if (data?.statusCode == 400) {
        Swal.fire({
          text: data?.message,
        });
      } else {
        navigate("/PaymentFailed");
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("user");
        window.location.href = "/Signup";
      } else {
        Swal.fire(error?.message);
      }
    }
    setLoading(false);
  };

  const freeSubscriber = () => {
    freeUserSubscription();
  };

  const getSubList = async () => {
    setLoading(true);
    try {
      const { data: res } = await api.get(
        API_URL + "/user-subscription/get-remaining-day"
      );
      if (res.statusCode === 200) {
        setValidDays(res?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div>
      <section className="container-fluid PageTitle_banner_section Pricing-select p-0">
        <div className="container">
          <h1 className="PageTitle_banner_heading">Pricing</h1>
        </div>
      </section>

      <section className="container-fluid Pricing">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-12 mt-4">
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-6 text-center Protection_Plans">
                  <h2 className="fw-bold">Plans</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {loading ? (
              <div></div>
            ) : (
              SubscriptionPlans?.sort((a, b) =>
                b?.amount > a?.amount ? -1 : 1
              ).map((plan, index) => (
                <>
                  {user?.paid == "paid" ? (
                    plan?.packageName != "Free " && (
                      <div className="col-12 col-md-12 col-lg-12 col-xl-5 mt-0 ">
                        {/* <div className={`card border-0 Free_Plans ${user?.name ? "fade-in" : ""}`}>
                    <div className="card-body">
                      <h3 className="text-center m-1">{user?.name} <br /> You are Already Subscribed User</h3>
                    </div> </div> */}
                      </div>
                    )
                  ) : (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-4 "></div>
                  )}
                </>
              ))
            )}
          </div>
        </div>
      </section>
      <section class="container-fluid Pricing">
        <div class="container">
          <div class="row mb-5 justify-content-center mx-auto">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              SubscriptionPlans?.sort((a, b) =>
                b?.amount > a?.amount ? -1 : 1
              ).map((plan, index) => (
                <>
                  {user?.paid == "paid" ? (
                    plan?.packageName != "Free " && (
                      <div className="col-12 col-md-12 col-lg-12 col-xl-5 mt-0 ">
                        {/* <h3 className="text-center text-danger m-1">{user?.name + ":"} <br /> You are Already Subscribed User </h3> */}

                        <div
                          className={`card border-0 Free_Plans ${
                            user?.name ? "fade-in" : ""
                          }`}
                        >
                          <div className="card-body">
                            <h3 className="text-center m-1">
                              {" "}
                              {validDays
                                ? `Your subscription will expire in ${validDays} days`
                                : "Your subscription has expired"}{" "}
                            </h3>
                          </div>{" "}
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-4 ">
                      <div className="card border-0 Free_Plans">
                        <div className="card-body">
                          <h3 className="text-center">{plan?.packageName}</h3>
                          <p className="text-center">{plan?.description}</p>
                          <div className="plans_list">
                            {plan?.features?.map((features, index) => (
                              <p key={index}>
                                <i className="fa-solid fa-check-double"></i>
                                {features}{" "}
                              </p>
                            ))}
                          </div>
                        </div>
                        <div className="card-footer card-footer-Pricing ">
                          {plan?.packageName == "Free " ? (
                            <h3> </h3>
                          ) : (
                            <div className="text-center m-2">
                              <br />
                              <div class="text-center">
                                {monthly ? (
                                  <h3>
                                    {plan?.monthly + "$ / " + "Monthly (USD)"}
                                  </h3>
                                ) : (
                                  <h3>
                                    {plan?.yearly + "$ / " + "Yearly (USD)"}
                                  </h3>
                                )}
                                <div class="d-flex justify-content-center my-3 Pricing-switch">
                                  <label class="form-check-label me-2">
                                    Yearly
                                  </label>
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={monthly}
                                      onClick={toogleBtn}
                                    />
                                  </div>
                                  <label class="form-check-label">
                                    Monthly
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <div className="text-center">
                        {
                          selectedPlan?.id == plan?.id ? <button className="btn btn-select " onClick={() => processPayment()}>
                            Process for payment
                          </button> : (<button className="btn btn-select " onClick={() => handleSelectPlan(index)}>
                            Select
                          </button>)
                        }

                      </div> */}
                          <div className="text-center">
                            {plan?.packageName != "Free " ? (
                              selectedPlan?.id === plan?.id ? (
                                <button
                                  className="btn btn-select"
                                  onClick={() => processPayment()}
                                >
                                  Proceed for payment
                                </button>
                              ) : (
                                <button
                                  className="btn btn-select"
                                  onClick={() => handleSelectPlan(index)}
                                >
                                  Select
                                </button>
                              )
                            ) : (
                              ""
                              // <button className="btn btn-select" onClick={freeSubscriber}>
                              //   Select
                              // </button>
                              // <p>You are on the free plan.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
