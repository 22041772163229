import React from "react";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Services/UserServices";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import Sign_Up from "../../assets/Sign_Up.png";


function ForgetPassword() {
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [emailValid, setEmailValid] = useState(true); // Add email validity state
  const [emailFormatValid, setEmailFormatValid] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  const onSubmitHandlerForgotPassword = async (e) => {
    e.preventDefault();

    // Validate email
    if (!email) {
      setEmailValid(false);
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailFormatValid(false);
      return;
    }
    setLoading(true)
    try {
      e.preventDefault();
      localStorage.setItem("userEmail", email);
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for the "Forgot Password" API
      const response = await axios.post(
        `${API_URL}/user/forget-password?` +
        `email=${email}`
      );

      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response?.data?.statusCode === 200) {
        // Show a success message to the user
        Swal.fire({
          title: "Success!",
          text: "OTP has been sent to your email address",
          icon: "success",
          button: "OK",
        });
        navigate("/OtpVerification");
      }
      else {
        Swal.fire({
          title: "Error!",
          text: "Provided email does not exist.",
          icon: "error",
          button: "OK",
        });
        setEmail('');

      }
    } catch (error) {

      if (error?.response?.data?.statusCode === 400) {
        Swal.fire({
          title: "Error!",
          text: "email must be a valid",
          icon: "error",
          button: "OK",
        });
      }
      else {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          button: "OK",
        });
      }
    }
    setEmail('')
    setLoading(false)
  };
  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         marginTop: "20%",
  //       }}
  //     >
  //       <Backdrop
  //         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //         open={loading}
  //       >
  //         <CircularProgress />
  //       </Backdrop>
  //     </Box>
  //   );
  // }
  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }
      <form onSubmit={onSubmitHandlerForgotPassword}>
        <section className="container-fluid Sign-section  Sign-section-Password">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h3 className="fw-bold">Forgot Password</h3>
                        {/* <p className="Sign_Up_subheading">
                          On the other hand, we denounce with righteous
                          indignation and dislike men who are so beguiled and
                          demoralized by the charms of pleasure of the moment.
                        </p> */}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Email<span className="redP">*</span></label>
                        <input
                          type="text"
                          className="form-control "
                          value={email || ""}
                          // onChange={(e) => setEmail(e.target.value)}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailValid(true); // Reset email validity on change
                            setEmailFormatValid(true);
                          }}
                          placeholder="Ex.-email@gmail.com"
                        />
                        {!emailValid && (
                          <span className="redP">
                            email required
                          </span>
                        )}
                        {!emailFormatValid && (
                          <span className="redP">Invalid email format</span>
                        )}
                      </div>
                      {/* <div className="text-end">
                                <a href="" className="forget_password">Resend Email</a>
                            </div> */}
                      <div>
                        <button className="btn Sign_btn_submit" type="submit">
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section-img">
                  <div className="card-body">
                    <img src={Sign_Up} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
}

export default ForgetPassword;
