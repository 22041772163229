import React, { useEffect, useState } from "react";
import Sign_Up from "../../assets/Sign_Up.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ForgetPassword from "./ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import { createUserAsync } from "../../Redux/Reducer/UserReducer";
import axios from "axios";
import { LoginUser } from "../../Services/UserServices";
import { useNavigate } from "react-router-dom";
// import { loginUserAsync  } from "../../Redux/Reducer/signInReducer";
import Swal from "sweetalert2"; // Import SweetAlert
import { useAuth } from "../../provider/AuthProvider";
import Login from "../Login";
import Register from "../Register";


// const schemaSignIn = yup.object().shape({
//   email: yup.string().email().required(),
//   password: yup.string().required("Password is required"),
// });

function Signup() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      return (window.location.href = "/");
    }
  }, []);

  // const {
  //   register: registerSignIn,
  //   handleSubmit: handleSubmitSignIn,
  //   reset: resetSignIn,
  //   formState: { errors: signInErrors },
  // } = useForm({
  //   resolver: yupResolver(schemaSignIn),
  // });

 

  // Assuming you have already imported SweetAlert in your HTML file

  // const onSubmitHandlerSignIn = async (data) => {
  //   setLoading(true);
  //   try {
  //     // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
  //     const response = await axios.post(
  //       "https://machanicalcalculator.microlent.com/api/user/login",
  //       data
  //     );
  

  //     // Check if the response indicates success (modify the condition based on your API response structure)
  //     if (response.data.statusCode == 200) {
  //       login(response.data.data);
  //    

  //       // Show a success message to the user
  //       Swal.fire({
  //         title: "Success!",
  //         text: "login successfully.",
  //         icon: "success",
  //         button: "OK",
  //       });
  //       navigate("/");
  //     } else if (response.data.statusCode == 401) {
  //       Swal.fire({
  //         title: "error!",
  //         text: "invalid credentials or user is blocked..",
  //         icon: "error",
  //         button: "OK",
  //       });
  //     }

  //     // Reset the form after successful submission
  //     // reset();
  //   } catch (error) {
 
  //     // Handle error response, e.g., show an error message to the user
  //     // Show a general error message to the user
  //     Swal.fire({
  //       title: "Error!",
  //       text: "An error occurred during registration.",
  //       icon: "error",
  //       button: "ok",
  //     });
  //   }
  //   setLoading(false);
  //   // resetSignIn();
  // };

 

  const [activeTab, setActiveTab] = useState("signIn");

  

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // resetForm();
  };

  const resetForm = () => {
    // resetSignUp();
    // resetSignIn();
  };

  return (
    <div>
      <div>
        <section className="container-fluid Sign-section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-lg-4">
                    <ul
                      className="nav nav-pills mt-5 mb-4 nav-pills-bg"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "signUp" ? "active" : ""
                          }`}
                          id="pills-Sign-Up-tab"
                          onClick={() => handleTabChange("signUp")}
                          type="button"
                          role="tab"
                          aria-controls="pills-Sign-Up"
                          aria-selected={activeTab === "signUp"}
                        >
                          Sign Up
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "signIn" ? "active" : ""
                          }`}
                          id="pills-Sign-In-tab"
                          onClick={() => handleTabChange("signIn")}
                          type="button"
                          role="tab"
                          aria-controls="pills-Sign-In"
                          aria-selected={activeTab === "signIn"}
                        >
                          Sign In
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade  ${
                      activeTab === "signUp" ? "active show" : ""
                    }`}
                    id="pills-Sign-Up"
                    role="tabpanel"
                    aria-labelledby="pills-Sign-Up-tab"
                    tabIndex="0"
                  >
                    <Register  activeTab={activeTab}/>
                  </div>
                  {/* ---sign in  */}
                  <div
                    className={`tab-pane fade ${
                      activeTab === "signIn" ? "active show" : ""
                    }`}
                    id="pills-Sign-In"
                    role="tabpanel"
                    aria-labelledby="pills-Sign-In-tab"
                    tabIndex="0"
                  >
                    <Login activeTab={activeTab}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Signup;
