import axios from "axios";
export const API_URL = "https://ductsizerpro.com/api";
// export const API_URL = "http://localhost:3001/api";

export const createUser = async (data) => {
  return await axios.post(`${API_URL}/user/signup`, data);
};

export const getUser = async () => {
  return axios.get(`${API_URL}/user/get-All`);
};

export const updateUser = async () => {
  return await axios.post(`${API_URL}/user/Update-user`);
};
export const LoginUser = async (data) => {
  return axios.post(`${API_URL}/user/login`, data);
};
export const contactUser = async (data) => {
  return axios.post(`${API_URL}/user/contact/create-or-update`, data);
};

// export const  = async ()=>{
//     return axios.post(`${API_URL}/user/login`)
// }
