import React from 'react'

function Terms_condition() {
    return (
        <div>
            <section className="container-fluid PageTitle_banner_section p-0">
                <div className="container">
                    <h1 className="PageTitle_banner_heading">TERMS & Condition</h1>
                </div>
            </section>
            <div className="col-12  mt-4 mb-4">
                <div className=" card border-0  ">
                    <div className="card-body ">
                        {/* <blockquote> A Terms and Conditions agreement acts as a legal contract between you (the company) and the user. It's where you maintain your rights to exclude users from your app in the event that they abuse your website/app, set out the rules for using your service and note other important details and disclaimers.

Having a Terms and Conditions agreement is completely optional. No laws require you to have one. Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).

Your Terms and Conditions agreement will be uniquely yours. While some clauses are standard and commonly seen in pretty much every Terms and Conditions agreement, it's up to you to set the rules and guidelines that the user must agree to.

Terms and Conditions agreements are also known as Terms of Service or Terms of Use agreements. These terms are interchangeable, practically speaking. More rarely, it may be called something like an End User Services Agreement (EUSA). </blockquote> */}
                        <h2>END USER LICENSE AGREEMENT</h2>
                        <p> This End User License Agreement is an agreement between you ("Licensee") and INSODEC ("Licensor") that sets out Licensee’s rights and obligations with respect to the DS.PRO Software(the Software) that you are using from Licensor's website. In order to use the Software, you must agree to the terms of this Agreement. By using the Software, Licensee agrees to abide by and be legally bound by all terms and conditions of this Agreement.
                        </p>
                        <p >If You do not agree to the terms of this agreement, do not access or use the Software.</p>
                        <p >if you do not have a valid license for the Software, you are not authorized to use the Software.</p>

                        <h4>
                            Software License:
                        </h4>
                        <ul>
                            <li>
                                The Software is protected by intellectual property laws and is a copyright of INSODEC.
                            </li>
                            <li>The Software is exclusively distributed by INSODEC, there are no other authorized suppliers of the Software. No reseller is authorized to sell DS.PRO</li>
                            <li>
                                The Software will stop functioning upon expiration of the License term. Any attempt to bypass expiration of the License term will be a violation of this License. In the event that such attempt causes the Software to stop functioning, Licensor will not be obligated to replace the Software or to refund any License fees.
                            </li>
                            <li>
                                Without prejudice to any other rights, licensor may terminate this agreement if you fail to comply with the terms and conditions of this agreement.
                            </li>
                            <li>
                                The Software consists of interactive Internet applications that perform a variety of communications over the Internet as part of its normal operation.  A number of communications features are automatic and are enabled by default. By using the Software, You agree to the Software's communications features. You are responsible for any telecommunications or other connectivity charges incurred through use of the Software.
                            </li>
                        </ul>
                        <h4>Commitment of the Licensee:</h4>
                        <ul>
                            <li>
                                Licensee is committed not reproduce, modify, translate, reverse engineer, reverse assemble, decompile, disassemble, or otherwise attempt to derive the underlying ideas, source code, algorithms on all or any part of the Software
                            </li>
                            <li>
                                Licensee will not remove, change, relocate, resize or modify any copyright or trademark that appear in the Software.
                            </li>
                            <li>
                                Respect for the Software intellectual property rights owned by INSODEC.
                            </li>
                            <li>
                                Licensee acknowledges and agrees that all rights, including but not limited to intellectual property rights and ownership in and to the Software, will at all times remain with Licensor, and Licensee has no rights in the Software except those expressly granted by this Agreement. Licensee will take reasonable steps to protect the Software from any use that is not specifically authorized by this Agreement.
                            </li>
                        </ul>
                        <h4>
                            INSODEC limitation of liability:
                        </h4>
                        <ul>
                            <li>
                                INSODEC is in no way liable to the User or to any third party for the loss of profit or for indirect, incidental, or consequential damages arising from the use or inability to use the Software.
                            </li>
                            <li>
                                INSODEC does not offer any guarantee relating to the results generated by the Software.
                            </li>
                            <li>
                                Under no circumstances shall INSODEC be held liable for the potential consequences that may result from a difference in production between a real system compared to the Software’s predictions.
                            </li>
                            <li>
                                INSODEC does not guarantee the safety or the reliability of the equipment and technical means linked to the running of the Software.
                            </li>
                            <li>
                                INSODEC cannot be held responsible for an act of computer hacking, for the temporary inaccessibility of the Software or for actions outside its control. No complaint, action or other measure can be raised against INSODEC in this regard.
                            </li>
                        </ul>
                        <h4>
                            Jurisdiction and applicable law:
                        </h4>
                        <p>
                            The contractual relationship between the parties is governed exclusively by the Australian law. Failure to comply with the general conditions exposes the infringer to the license cancellation or/ and to legal actions.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Terms_condition