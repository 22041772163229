import React from "react";
//import { DiCss3, DiJavascript, DiNpm } from "react-icons/di";
import {  IoAdd, IoRemove } from "react-icons/io5";

//import { FaList } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";


function DirectoryTreeView({ data: folder, onSelected }) {
  const data = flattenTree({
    name: "",
    children: folder,
  });
  return (
    <div>
      <div className="directory">
        <TreeView
          data={data}
          aria-label="directory tree"
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            level,
          }) => (
            <div {...getNodeProps()} style={{"lineHeight":0,     marginBottom: "3px",marginLeft: 20 * (level - 1) }}>
              {isBranch ? (
                <FolderIcon isOpen={isExpanded} />
              ) : (
                <FileIcon filename={element.name} />
              )}

            <span style={{"margin": "5px", "lineHeight": 0.9}}>
            {element.name}
              </span> 
            </div>
          )}
          onNodeSelect={(selected) => onSelected(selected)}
        />
      </div>
    </div>
  );
}

const FolderIcon = ({ isOpen }) =>
  isOpen ? (
    <IoRemove color="black" className="icon" />
  ) : (
    <IoAdd color="black" className="icon" />
  );

const FileIcon = ({ filename }) => {

  
  return null;
};

export default DirectoryTreeView;
