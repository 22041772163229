import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { contactUser } from '../../Services/UserServices'
const initialState = {
  user : {},
  status: 'idle',
  error: null,
};

export const contactUserAsync = createAsyncThunk('/ ', async (data) => {
  const response = await contactUser(data)
  return response.data;
});



const userSlice = createSlice({
  name: 'contactUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUserAsync.pending, (state) => {
      
        state.status = 'loading';
      })
      .addCase(contactUserAsync.fulfilled, (state, action) => {
   
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(contactUserAsync.rejected, (state, action) => {
       
       
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export default userSlice.reducer;
