import React from "react";
import logo from "../../assets/site_logo.png";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
import Product from "../Pages/Product";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "../../Services/UserServices";
import { useState } from "react";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  let routes = [
    {
      id: 1,
      name: "Home",
      path: "/",
      Active: pathname === "/" ? "active" : "",
    },
    {
      id: 2,
      name: "Free Calculator",
      path: "/FreeCalculator",
      Active: pathname === "/FreeCalculator" ? "active" : "",
    },
    {
      id: 3,
      name: "About Us",
      path: "/About",
      Active: pathname === "/About" ? "active" : "",
    },
    {
      id: 4,
      name: "Pricing",
      path: "/Pricing",
      Active: pathname === "/Pricing" ? "active" : "",
    },
    {
      id: 5,
      name: "Contact Us",
      path: "/Contact",
      Active: pathname === "/Contact" ? "active" : "",
    },
  ];

  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(false);

  // if (user && user.token !== "token") {
  //   routes.push({
  //     id: 4,
  //     name: "Pricing",
  //     path: "/Pricing",
  //     Active: pathname === '/Pricing' ? "active" : ""
  //   });
  // }

  const handleFreeTrialClick = async () => {
    setHandleNav(true);
    if (!user) {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Please Login First',
      //   text: 'You need to be logged in to access the free trial.',
      // });
      navigate("/Signup?activeTab=signUp");
    } else if (user.paid !== "paid") {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${API_URL}/payment/success-free-user`,
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        if (data?.statusCode == 200 || data?.statusCode == 201) {
          const user = JSON.parse(localStorage.getItem("user") ?? "{}");
          if (user) {
            user.paid = "paid";
            localStorage.setItem("user", JSON.stringify(user));
          }
          Swal.fire({
            title: "You are Subscribed for 1-month",
          });

          window.location.reload();
        } else if (data?.statusCode == 400) {
          Swal.fire({
            text: data?.message,
          });
        } else {
          navigate("/PaymentFailed");
        }
      } catch (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("user");
          window.location.href = "/Signup";
        } else {
          Swal.fire(error?.message);
        }
        setLoading(false);
      }
    }
  };

  // Get the token from local storage

  const [handleNav, setHandleNav] = useState(true);
  const handleNavBar = () => {
    setHandleNav(true);
  };

  const handleNavBarStatus = () => {
    setHandleNav(false);
  };

  const handlePage = () => {
    setHandleNav(true);
  };

  const capitalizeName = (name) => {
    if (name) {
      const names = name.split(" ");
      const capitalizedNames = names.map(
        (n) => n.charAt(0).toUpperCase() + n.slice(1)
      );
      return capitalizedNames.join(" ");
    }
    return "";
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure you want to cancel your subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user clicks 'Yes'
        // Perform the subscription cancellation logic here
        // For example, call your API to cancel the subscription

        // Show the second popup confirming the cancellation
        Swal.fire({
          title: "Your Subscription has been canceled",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    });
  };

  return (
    <div>
      <header className="container-fluid header_section">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="container">
            <Link className="navbar-brand logo_area p-0" to="/">
              <img src={logo} alt="Duct Sizer Pro" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleNavBarStatus}
            >
              <i className=" fa-solid fa-bars"></i>
            </button>
            <div
              className={`collapse navbar-collapse ${handleNav ? "" : "show"}`}
              id="navbarScroll"
            >
              <ul className="navbar-nav me-auto  my-lg-0 navbar-nav-scroll">
                {routes.map(({ id, name, path, Active }) =>
                  user &&
                  path === "/FreeCalculator" &&
                  user.paid == "paid" ? null : (
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${Active}`}
                        to={path}
                        id={id}
                        onClick={handleNavBar}
                      >
                        {name}
                      </Link>
                    </li>
                  )
                )}
              </ul>

              <div className="d-flex align-items-center nav_right_side">
                {(!user || (user && user.paid !== "paid")) && (
                  <ul
                    className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
                    // style={"--bs-scroll-height: 100px;"}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={handleFreeTrialClick}
                      >
                        Free Trial
                      </a>
                    </li>
                  </ul>
                )}
                {user ? (
                  <div className="d-flex align-items-center">
                    {/* <span className="me-2 text-white">{user?.name}</span> */}

                    <div className="dropdown">
                      <button
                        className="btn btn_signin dropdown-toggle m-0 p-0"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {" "}
                        <i className="fa fa-user" aria-hidden="true"></i>
                        {" " + capitalizeName(user?.name) + " "}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {user?.paid == "paid" ||
                        (user?.paid === "unpaid" && user?.userSubs === true) ? (
                          <>
                            <li>
                              <a className="dropdown-item" href="/Project">
                                <i
                                  className="fa fa-list"
                                  aria-hidden="true"
                                ></i>{" "}
                                Project List
                              </a>
                            </li>
                            {((user?.paid === "unpaid" &&
                              user?.userSubs !== true) ||
                              user?.paid === "paid") && (
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={handleCancel}
                                >
                                  <i
                                    className="fa fa-window-close"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Cancel Subscription
                                </button>
                              </li>
                            )}
                            <li>
                              <button
                                onClick={logout}
                                className="dropdown-item"
                              >
                                <i
                                  className="fa fa-sign-out"
                                  aria-hidden="true"
                                ></i>{" "}
                                Logout
                              </button>
                            </li>{" "}
                          </>
                        ) : (
                          <>
                            <li>
                              <button
                                onClick={logout}
                                className="dropdown-item"
                              >
                                <i
                                  className="fa fa-sign-out"
                                  aria-hidden="true"
                                ></i>{" "}
                                Logout
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Link
                    to="Signup"
                    className="btn btn_signin"
                    onClick={handlePage}
                  >
                    SIGN IN
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
