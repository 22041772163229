import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import ForgetPassword from "./ForgetPassword";

import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { loginUserAsync  } from "../../Redux/Reducer/signInReducer";
import Swal from "sweetalert2"; // Import SweetAlert
import { useAuth } from "../provider/AuthProvider";
import Sign_Up from "../assets/Sign_Up.png";

import { Link } from "react-router-dom";
import Spinner from "./common/Spinner";
import { API_URL } from "../Services/UserServices";
import Terms_condition from "./Pages/Terms_condition";

const schemaSignUp = yup.object({
  email: yup
    .string()
    // .email(" ")
    .required("email required")
    .max(200, "email must be at most 200 characters long")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "email must be a valid"
    ),
  phoneNumber: yup
    .string()
    .notRequired()
    .test("Phone number must be ten digit", (value) => {
      return !value || /^\d{10}$/.test(value);
    }),
  //.required("phone number required").matches(/^[0-9]+$/, "phone number must contain only digits and no spaces")
  // .min(8, "phone number must be at least 8 characters long")
  // .max(14, "phone number must be at most 14 characters long"),

  firstName: yup
    .string()
    .trim("first name must contain only alphabets and no spaces")
    .required("first name required")
    .min(3, "first name must be longer than or equal to 3 characters")
    .max(50, "first name must be shorter than or equal to 50 characters")
    .matches(
      /^[a-zA-Z]+$/,
      "first name must contain only alphabets and no spaces"
    ),
  lastName: yup
    .string()
    .trim()
    .required("last name required")
    .min(3, "last name must be longer than or equal to 3 characters")
    .max(50, "last name must be shorter than or equal to 50 characters")
    .matches(
      /^[a-zA-Z]+$/,
      "last name must contain only alphabets and no spaces"
    ),

  password: yup
    .string()
    .required("password required")
    .min(8, "password must be at least 8 characters")
    .max(32, "password cannot exceed 32 characters")
    .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#*()_+,.\/;'":?><])[A-Za-z\d@$!%*?&^#*()_+,.\/;'":?><]+$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\])[A-Za-z\d@$!%*?&^#*()_+,.\/;'":?><[\]{}|\\]+$/,
      "password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "password must match")
    .required("confirm password required"),
});

function Register({ activeTab }) {
  const [loading, setLoading] = useState(false);
  const [registrationFailed, setRegistrationFailed] = useState(false);
  const [isTerm, setIsTerm] = useState(false);
  const onSubmitHandlerSignUp = async (data) => {
    setLoading(true); // Set loading to true before making the API call
    if (!isTerm) {
      // Show an error message using SweetAlert if the checkbox is not checked
      setRegistrationFailed(true);
      setLoading(false);
      return; // Return early, preventing the API call
    }
    let NewData = {
      firstName: `${data.firstName}`,
      lastName: `${data.lastName}`,
      mobileNo: `${data.phoneNumber}`,
      email: `${data.email}`,
      password: `${data.password}`,
    };
    // let userData = JSON.stringify(NewData)

    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
      const response = await axios.post(`${API_URL}/user/signup`, NewData);

      if (response.data.statusCode === 200) {
        // Show SweetAlert for successful registration
        Swal.fire({
          icon: "success",
          title: "Registration Successful",
          text: "You have been registered Successfully.",
        });
        window.location.href = "/signup";
      }

      if (response?.data?.statusCode == 400) {
        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: response.data.message,
        });
      }
      // Clear the form after successful submission
      // resetSignUp();
    } catch (error) {
      if (error.response?.data?.status === 400) {
        // Show SweetAlert for email and mobile already existing
        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: error.response.data.message,
        });
      } else {
        // Handle other error responses
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error?.message || "an error occurred during registration.",
        });
      }
    }
    setLoading(false);
  };

  const {
    register: registerSignUp,
    handleSubmit: handleSubmitSignUp,
    reset: resetSignUp,
    formState: { errors: signUpErrors },
    watch: watch,
  } = useForm({
    resolver: yupResolver(schemaSignUp),
  });
  const terms = () => {
    window.location.href = "Terms&Condition";
  };
  useEffect(() => {
    if (activeTab !== "signUp") {
      resetSignUp();
    }
  }, [activeTab, resetSignUp]);
  return (
    <div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <div className="card Sign-Up-card-section">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h3 className="fw-bold">SIGN UP</h3>
                  {/* <p className="Sign_Up_subheading">
                    On the other hand, we denounce with righteous indignation
                    and dislike men who are so beguiled and demoralized by the
                    charms of pleasure of the moment.
                  </p> */}
                </div>
                <form onSubmit={handleSubmitSignUp(onSubmitHandlerSignUp)}>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        First Name<span className="redP">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Ex.- john"
                        {...registerSignUp("firstName")}
                      />
                      <p style={{ color: "red" }}>
                        {signUpErrors.firstName?.message}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Last Name<span className="redP">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Ex.-doe"
                        {...registerSignUp("lastName")}
                      />
                      <p style={{ color: "red" }}>
                        {signUpErrors.lastName?.message}
                      </p>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        Phone Number<span className="redP"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Ex.- 00012852166 "
                        {...registerSignUp("phoneNumber")}
                      />
                      <p style={{ color: "red" }}>
                        {signUpErrors.phoneNumber?.message}
                      </p>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        Email<span className="redP">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        {...registerSignUp("email")}
                        placeholder="Ex.-email@gmail.com"
                      />
                      <p style={{ color: "red" }}>
                        {signUpErrors.email?.message}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Password<span className="redP">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control "
                        {...registerSignUp("password")}
                        placeholder="Ex.- ******** "
                      />
                      <p style={{ color: "red" }}>
                        {signUpErrors.password?.message}
                      </p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Confirm Password<span className="redP">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control "
                        {...registerSignUp("confirm_password", {
                          required: true,
                          validate: (val) => {
                            if (watch("password") != val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                        placeholder="Ex.- ******** "
                      />
                      <p style={{ color: "red" }}>
                        {signUpErrors.confirm_password?.message}
                      </p>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="mb-3 ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          value={isTerm}
                          checked={isTerm}
                          onChange={(e) => {
                            setIsTerm(e.target.value);
                            setRegistrationFailed(!registrationFailed);
                          }}
                        />
                        <label>
                          <div style={{ marginLeft: "10px" }}>
                            I agree to the{" "}
                            <span
                              onClick={terms}
                              className="termsAndConditions"
                              style={{
                                color: registrationFailed ? "#3f2a6b" : " ",
                              }}
                            >
                              Terms & Conditions
                            </span>
                            <span className="redP">*</span>
                          </div>
                        </label>
                        {!registrationFailed && (
                          <p style={{ color: "red" }}>
                            please agree to the Terms & Conditions
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      {loading ? (
                        <button
                          className="btn Sign_btn_submit"
                          disabled
                          type="submit"
                        >
                          Loading..
                        </button>
                      ) : (
                        <button
                          className="btn Sign_btn_submit"
                          type="submit"
                          disabled={loading}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="card Sign-Up-card-section-img">
            <div className="card-body">
              <img src={Sign_Up} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
