import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Reducer/UserReducer';
import ContactReducer from './Reducer/ContactReducer';
// import signInReducer from './Reducer/signInReducer';

const rootReducer = {
  user: userReducer,
  contactUser:ContactReducer,
  // loginUser:signInReducer,
};

const store = configureStore({
  reducer: rootReducer,
  // Additional configuration options...
});

export default store;
